import React, { Component } from 'react';
import './Home.scss';

class Home extends Component {
  render() {
    return <div className="home">HOME</div>;
  }
}

export default Home;
