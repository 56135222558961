import React, { Component } from 'react';
import './Battle.scss';

class Battle extends Component {
  render() {
    return <div className="battle">BATTLE</div>;
  }
}

export default Battle;
